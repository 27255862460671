import { AUTH_TOKEN, AUTH_USER } from "constants/AuthConstant";
import { useState, useContext, createContext, useEffect } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    token: "",
  });
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(AUTH_USER));
    const token = localStorage.getItem(AUTH_TOKEN);
    //console.log("31---->", user, token);
    if (user && token) {
      setAuth({
        ...auth,
        user: user,
        token: token,
      });
    }
  }, []);
  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

// custom hook
const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
